import { useEffect, useState, useCallback } from 'react';
//import * as copy from 'copy-to-clipboard';
import copy from 'copy-to-clipboard';
import useToaster from 'framework/Toaster/useToaster';

function useCopyToClipboard(resetInterval: number | null = null) {
  const [isCopied, setIsCopied] = useState(false);
  const toaster = useToaster();

  const handleCopy = useCallback((text: string | undefined) => {
    if (typeof text === 'string' || typeof text == 'number') {
      copy(text.toString());
      setIsCopied(true);
    } else {
      setIsCopied(false);
      console.error(`Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`);
    }
  }, []);

  useEffect(() => {
    let timeout: any;
    if (isCopied && resetInterval) {
      toaster.info(
        'Kopiert til utklippstavle',
        `Verdien ble kopiert til utklippstavlen og kan limes inn andre steder.`,
      );
      timeout = setTimeout(() => setIsCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);

  return { isCopied, handleCopy };
}

export default useCopyToClipboard;
