import { IconButton } from "@tradesolution/iceberg-ui-react";
import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { Modal } from "react-bootstrap";

interface Props {
	object: object | object[];
	show: boolean;
	onClose: () => void;
}

const PrettyPrintJsonModal = (props: Props) => {

	const handleClose = () => {
		props.onClose();
	}

	const jsonString = JSON.stringify(props.object, null, 2); // Convert object to JSON string


	return (
		<Modal size="lg" show={props.show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Json preview</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<pre>{jsonString}</pre>
			</Modal.Body>
			<div className="p-2">
				<CopyToClipboardIcon val={jsonString} showDesc />
			</div>

			<Modal.Footer>
				<IconButton className="col" icon="close" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>
			</Modal.Footer>
		</Modal>
	)
}

export default PrettyPrintJsonModal;